import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useHistory } from "react-router-dom";
import { service } from "../../network/Home/service";
import { convertTime } from "../../Utils/utils";
import { useSelector, useDispatch } from "react-redux";
import ReadMoreAndLess from "react-read-more-less";
import "../../css/Imagestyle.css";
import freeimg from "../../images/free.png";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { data } from "jquery";
import { confirmAlert } from "react-confirm-alert";
var showsImageUrl = "https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/";

const Show = ({ param, update }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapseIndex, setCollapseIndex] = useState("");
  const [disContent, setDisContent] = useState(false);
  const [shows, setShows] = useState([]);
  const [hover, setHover] = useState(false);
  const [focusedId, setFocusedId] = useState(-1);
  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  useEffect(() => {
    if (param !== undefined) {
      setShows(param);
    }
  }, [param, update]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const hoverFunction = (flag, index) => {
    setHover(flag);
    setFocusedId(index);
  };
  const addtoMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn === "true" && userId) {
      service.addToMyPlayList(show.show_id, 1).then((response) => {
        update.clickHandler();
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  const removeFromMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn === "true" && userId) {
      service.addToMyPlayList(show.show_id, 0).then((response) => {
        update.clickHandler();
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  function displayContent(cont) {
    setDisContent(!disContent);
    setCollapseIndex(cont);
  }
  const WatchWithoutAdsPopUp = (onClickYes, onClickNo) => {
    confirmAlert({
      title: "",
      message: "Watch without ads?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onClickYes();
          },
        },
        {
          label: "No",
          onClick: () => {
            onClickNo();
          },
        },
      ],
    });
  };
  // const onClickPlaybutton = (showId, videoId, singleVideo) => {
  //   debugger;
  //   // ToastsStore.warning(`${showId},${videoId},${singleVideo}`);
  //   console.log("continueWatching", showId, videoId, singleVideo);
  //   if (showId && videoId) {
  //     service.getVideoDetails(videoId).then((response) => {
  //       console.log("response", response);
  //       if (response.success == true && response.data) {
  //         let videoDetails = response.data;
  //         if (videoDetails && videoDetails.free_video != true) {
  //           // Not a free video
  //           service
  //             .videoSubscription(videoDetails.video_id)
  //             .then((response) => {
  //               let videoSubLists = response.data;
  //               let subFlag = true;
  //               let uId = service.getCookie("guestUserId");
  //               let user_id = service.getCookie("userId");
  //               if (user_id) {
  //                 uId = user_id;
  //               }
  //               service.checkUserSubscription(uId).then((useResponse) => {
  //                 var userData = useResponse.data;
  //                 if (useResponse.success == true) {
  //                   if (useResponse.forcibleLogout === true) {
  //                     alert(
  //                       "Sorry, You’ve reached the maximum Device limit. Please log in again!"
  //                     );
  //                     service.logoutAll(uId).then((res) => {
  //                       setTimeout(() => {
  //                         redirectToLogin();
  //                       }, 1000);
  //                     });
  //                   } else if (useResponse.session_expired === true) {
  //                     ToastsStore.warning("Sorry! Session Has Expired");
  //                     redirectToLogin();
  //                   } else {
  //                     videoSubLists.map(function (subscription, index) {
  //                       if (useResponse.data.length == 0 && subFlag) {
  //                         subFlag = false;
  //                         service.setCookie("showId", showId, 10);
  //                         service.setCookie("videoId", videoId, 10);
  //                         history.push({
  //                           pathname: "/SubscriptionList",
  //                           state: {
  //                             videoData: videoId,
  //                           },
  //                         });
  //                       } else {
  //                         let subscribedVideo = userData.filter(
  //                           (e) =>
  //                             e.sub_id == subscription.publisher_subscription_id
  //                         );
  //                         if (
  //                           subscribedVideo.length == 0 &&
  //                           index + 1 < videoSubLists.length
  //                         ) {
  //                           return;
  //                         }
  //                         if (
  //                           subscribedVideo.length == 0 &&
  //                           subFlag &&
  //                           index + 1 == videoSubLists.length
  //                         ) {
  //                           subFlag = false;
  //                           service.setCookie("showId", showId, 10);
  //                           service.setCookie("videoId", videoId, 10);
  //                           history.push({
  //                             pathname: "/SubscriptionList",
  //                             state: {
  //                               videoData: videoId,
  //                             },
  //                           });
  //                         } else if (subFlag) {
  //                           subFlag = false;
  //                           service.setCookie("showId", showId, 10);
  //                           localStorage.setItem("ContinueWatching", "true");
  //                           localStorage.setItem("ContinueWatching", "true");
  //                           history.push({
  //                             pathname: "/videoDetails",
  //                             // state: { movie: movie, showId: ShowId },
  //                             // pathname: "/videoplayer",
  //                             state: {
  //                               show_details: videoDetails,
  //                               singleVideo: singleVideo,
  //                               showId: showId,
  //                             },
  //                           });
  //                         }
  //                       }
  //                     });
  //                   }
  //                 }
  //               });
  //             });
  //         } else if (videoDetails && videoDetails.free_video == true) {
  //           //free video
  //           if (
  //             videoDetails.premium_flag == 1 ||
  //             videoDetails.rental_flag == 1 ||
  //             videoDetails.payper_flag == 1
  //           ) {
  //             //free video with subscription
  //             let uId = service.getCookie("guestUserId");
  //             let user_id = service.getCookie("userId");
  //             if (user_id) {
  //               uId = user_id;
  //             }
  //             const onClickNo = () => {
  //               localStorage.setItem("ContinueWatching", "true");
  //               history.push({
  //                 pathname: "/videoDetails",
  //                 // state: { movie: movie, showId: ShowId },
  //                 // pathname: "/videoplayer",
  //                 state: {
  //                   show_details: videoDetails,
  //                   singleVideo: singleVideo,
  //                   showId: showId,
  //                 },
  //               });
  //             };
  //             const onClickYes = () => {
  //               service.setCookie("showId", showId, 10);
  //               service.setCookie("videoId", videoDetails.video_id, 10);
  //               history.push({
  //                 pathname: "/SubscriptionList",
  //                 state: {
  //                   videoData: videoId,
  //                 },
  //               });
  //             };

  //             service
  //               .videoSubscription(videoDetails.video_id)
  //               .then((response) => {
  //                 let videoSubDetails = response.data;
  //                 let subFlag = true;
  //                 service.checkUserSubscription(uId).then((useResponse) => {
  //                   if (useResponse.success == true) {
  //                     var userSubDetails = useResponse.data;
  //                     if (useResponse.forcibleLogout === true) {
  //                       alert(
  //                         "Sorry, You’ve reached the maximum Device limit. Please log in again!"
  //                       );
  //                       service.logoutAll(uId).then((res) => {
  //                         setTimeout(() => {
  //                           redirectToLogin();
  //                         }, 1000);
  //                       });
  //                     } else if (useResponse.session_expired === true) {
  //                       ToastsStore.warning("Sorry! Session Has Expired");
  //                       redirectToLogin();
  //                     } else {
  //                       videoSubDetails.map(function (subscription, index) {
  //                         if (useResponse.data.length == 0 && subFlag) {
  //                           subFlag = false;
  //                           WatchWithoutAdsPopUp(onClickYes, onClickNo);
  //                         } else {
  //                           let subscribedVideo = userSubDetails.filter(
  //                             (e) =>
  //                               e.sub_id ==
  //                               subscription.publisher_subscription_id
  //                           );
  //                           if (
  //                             subscribedVideo.length == 0 &&
  //                             index + 1 < videoSubDetails.length
  //                           ) {
  //                             return;
  //                           }
  //                           if (
  //                             subscribedVideo.length == 0 &&
  //                             subFlag &&
  //                             index + 1 == videoSubDetails.length
  //                           ) {
  //                             subFlag = false;
  //                             WatchWithoutAdsPopUp(onClickYes, onClickNo);
  //                           } else if (subFlag) {
  //                             subFlag = false;
  //                             localStorage.setItem("ContinueWatching", "true");
  //                             history.push({
  //                               pathname: "/videoDetails",
  //                               // state: { movie: movie, showId: ShowId },
  //                               // pathname: "/videoplayer",
  //                               state: {
  //                                 show_details: videoDetails,
  //                                 singleVideo: singleVideo,
  //                                 showId: showId,
  //                               },
  //                             });
  //                           }
  //                         }
  //                       });
  //                     }
  //                   }
  //                 });
  //               });
  //           } else {
  //             //free video without subscription
  //             localStorage.setItem("ContinueWatching", "true");
  //             history.push({
  //               pathname: "/videoDetails",
  //             //  state: { movie: movie, showId: ShowId },
  //               // pathname: "/videoplayer",
  //               state: {
  //                 show_details: videoDetails,
  //                 singleVideo: singleVideo,
  //                 showId: showId,
  //               },
  //             });
  //           }
  //         }
  //       } else {
  //         history.push("/404");
  //       }
  //     });
  //   }
  // };


  
  const onClickPlaybutton = (ShowId, videoId) => {
    console.log("continueWatching1", ShowId, videoId);
    
    if (ShowId && videoId) {
      service.getShowDetails(ShowId).then((response) => {
        console.log("response", response);
        if (response.success == true && response.data && response.data.videos) {
          let videoList = response.data.videos.filter(
            (e) => e.video_id == videoId
          );
          console.log("response", videoList);
          if (
            videoList &&
            videoList[0] &&
            videoList[0].free_video != true
            // videoList[0].subscriptions &&
            // videoList[0].subscriptions.length > 0
          ) {
            console.log("inside if");
            let movie = videoList[0];
            service.videoSubscription(movie.video_id).then((response) => {
              let videoDetails = response.data;
              let subFlag = true;
              let uId = service.getCookie("guestUserId");
              let user_id = service.getCookie("userId");
              if (user_id) {
                uId = user_id;
              }
              service.userSubscription(uId).then((useResponse) => {
                if (useResponse.success == true) {
                  var userData = useResponse.data;
                  if (useResponse.forcibleLogout === true) {
                    alert(
                      "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                    );
                    service.logoutAll(uId).then((res) => {
                      setTimeout(() => {
                        redirectToLogin();
                      }, 1000);
                    });
                  } else if (useResponse.session_expired === true) {
                    ToastsStore.warning("Sorry! Session Has Expired");
                    redirectToLogin();
                  } else {
                    videoDetails.map(function (subscription, index) {
                      if (useResponse.data.length == 0 && subFlag) {
                        subFlag = false;
                        service.setCookie("showId", ShowId, 10);
                        service.setCookie("videoId", movie.video_id, 10);
                        history.push({
                          pathname: "/SubscriptionList",
                          state: {
                            videoData: movie.video_id,
                          },
                        });
                      } else {
                        let subscribedVideo = userData.filter(
                          (e) =>
                            e.sub_id == subscription.publisher_subscription_id
                        );
                        if (
                          subscribedVideo.length == 0 &&
                          index + 1 < videoDetails.length
                        ) {
                          return;
                        }
                        if (
                          subscribedVideo.length == 0 &&
                          subFlag &&
                          index + 1 == videoDetails.length
                        ) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          service.setCookie("videoId", movie.video_id, 10);
                          history.push({
                            pathname: "/SubscriptionList",
                            state: {
                              videoData: movie.video_id,
                            },
                          });
                        } else if (subFlag) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          localStorage.setItem("ContinueWatching", "true");
                          history.push({
                            pathname: "/videoDetails",
                            state: { movie: movie, show_id: ShowId },
                          });
                        }
                      }
                    });
                  }
                }
              });
            });
          } else {
            if (videoList && videoList[0]) {
              let movie = videoList[0];
              service.setCookie("showId", ShowId, 10);
              localStorage.setItem("ContinueWatching", "true");
              history.push({
                pathname: "/videoDetails",
                state: { movie: movie, show_id: ShowId },
              });
            }
          }
        } else {
          history.push("/404");
        }
      });
    }
  };
  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    // service.eraseCookie("showId");
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    sessionStorage.removeItem("applaunch");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 1500);
  };

  return (
    // <div className="carouselContent categoryShowWrapper">
    <div className="carouselContent ">
      <Carousel responsive={responsive}>
        {shows &&
          shows.map((show, index) => {
            return (
              <div
                className="movieTile"
                key={index}
                style={{ padding: "12px" }}
              >
                <div
                  className={
                    hover === true && focusedId === index
                      ? "movieTileImage movieTileImageOpen"
                      : "movieTileImage"
                  }
                  id={index}
                  onMouseOver={() => {
                    hoverFunction(true, index);
                  }}
                  onMouseLeave={() => {
                    hoverFunction(false, index);
                  }}
                >
                  <div
                    onClick={() => {
                      //debugger
                      if (show.watched_duration > 0) {
                        onClickPlaybutton(show.show_id, show.video_id);
                      } else {
                        history.push({
                          pathname: "/home/movies",
                          search: encodeURI(`show_id=${show.show_id}`),
                        });
                      }
                      console.log("data.ShowId", data.category_id);
                    }}
                    className={
                      hover === true && focusedId === index
                        ? "movieTileIcon "
                        : "movieTileIcon  movieTileHoverOpened"
                    }
                  >
                    <svg
                      className="svgIcon movieTilePlayIcon"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 62 62"
                      style={{ fill: "currentcolor" }}

                      // onClick={() => {

                      //   if (show.watched_duration > 0) {
                      //     history.push({
                      //       pathname: "/videoDetails",
                      //       search: encodeURI(`show_id=${show.show_id}`),
                      //     });
                      //   } else {
                      //     history.push({
                      //       pathname: "/videoDetails",
                      //       // pathname: "/home/movies",
                      //       search: encodeURI(`show_id=${show.show_id}`),
                      //     });
                      //   }
                      // }}
                    >
                      <circle
                        r="30"
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        cx="31"
                        cy="31"
                      ></circle>
                      <path
                        fill="currentColor"
                        d="M28.42,37.6c-2,1-3.42,0-3.42-2.35v-8.5c0-2.34,1.38-3.39,3.42-2.35l9,4.7c2,1,2.11,2.76.07,3.8Z"
                      ></path>
                    </svg>
                  </div>
                  {show.is_free_video === true ? (
                    <img
                      src={freeimg}
                      style={{
                        width: "20%",
                        height: "13%",
                        position: "absolute",
                        zIndex: "1",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {show.logo && (
                    <div
                      className="moviePoster"
                      style={{
                        backgroundImage: `url(${showsImageUrl + show.logo})`,
                      }}
                    >
                      <div className="FeNml"></div>
                    </div>
                  )}

                  <div
                    className={
                      hover === true && focusedId === index
                        ? "wishlistPosition wishlistTranslate wishlistParentOpen"
                        : "wishlistPosition wishlistTranslate wishlistParentClose"
                    }
                  >
                    <div className="wishlistButton">
                      <div
                        className={
                          hover === true && focusedId === index
                            ? "wlgradientPosition wlgradientTranslate wlgradientOpen"
                            : "wlgradientPosition wlgradientTranslate wlgradientClose"
                        }
                        style={{
                          backgroundImage:
                            "linear-gradient(rgba(38, 38, 45, 0.5), rgba(38, 38, 45, 0.5))",
                          backgroundPosition: "center bottom",
                          backgroundSize: "cover",
                        }}
                      ></div>
                      {show.watchlist_flag === 1 ? (
                        <div className="wishlistTextWrapper">
                          <div
                            className="wishlistText"
                            onClick={() => {
                              removeFromMylistFunction(show);
                            }}
                          >
                            Remove from My List{" "}
                          </div>
                        </div>
                      ) : show.watchlist_flag === null ||
                        show.watchlist_flag === 0 ? (
                        <div className="wishlistTextWrapper">
                          <div
                            className="wishlistText"
                            onClick={() => {
                              addtoMylistFunction(show);
                            }}
                          >
                            Add to My List
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {show.watched_percentage != undefined ? (
                  <div className="progressbar__div">
                    <div class="progressbar-border">
                      <div
                        class="progressbar-grey"
                        style={
                          show.watched_percentage == 0
                            ? { height: "4px", width: "1%" }
                            : {
                                height: "4px",
                                width: `${show.watched_percentage}%`,
                              }
                        }
                      ></div>
                    </div>
                  </div>
                ) : null}
                <section className="movieTextWrapper movieTextWrapperPadding">
                  <div className="movieTextFlex">
                    <h3>
                      {show.show_name && (
                        <div
                          className="linkButton movieTextHeading"
                          onClick={() => {
                            // history.push({
                            //   pathname: "/home/movies",
                            //   search: encodeURI(`show_id=${show.show_id}`),
                            // });

                            if ((show.message = "Continue Watching List")) {
                              // if (show.watched_duration > 0) {
                              onClickPlaybutton(show.show_id, show.video_id);
                              // }
                            } else {
                              history.push({
                                pathname: "/home/movies",
                                search: encodeURI(`show_id=${show.show_id}`),
                              });
                            }
                          }}
                        >
                          {/* {show.is_free_video === true ?  <img src={freeimg} style={{width:"20%",height:"45%"}}/> : ""} */}
                          {show.show_name}
                        </div>
                      )}
                    </h3>

                    <div
                      className="movieCatYear"
                      style={{ display: "contents" }}
                    >
                      <div>
                        {show && (
                          <div className="movieYear">
                            <div
                              className="_1MmGl"
                              style={{
                                marginRight: "auto",
                              }}
                            >
                              {show.year ? `(${show.year})` : ""}
                              {show.year && show.duration_text ? " . " : ""}
                              {show.duration_text && show.duration_text}
                            </div>
                            {show.rating && (
                              <div className="movieCensorBox moviecensorText">
                                {show.rating}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {show.category_names && (
                        <div className="movieCategory mcMargin">
                          <div>{show.category_names}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};
export default Show;

